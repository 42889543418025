import React from 'react';
import './engagement.scss';

const Engagement = ({output}) => {

	if(!output){//parent (Cards) handles errors
      return <div className="engagement-empty"/>
    }

	let value = Object.values(output)[0];
	let barWidth = value*0.8+5; //to fit div's width

	return <div className="engagement">
		<div className="info">Consumption Average</div>
		<div className="bar" style={{width: barWidth+"px"}}></div>
		<div className="label">{value}</div>
	</div>
};

export default Engagement;
