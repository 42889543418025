//convert data into associations cloud
import $ from 'jquery';

import utils from './../../common/utils';

const BubblesData = {

  convert: function (results, candidates) {

    /*
    response - example for 2 names & 3 associations
    ========

    data: {
      bubbles: {
        Name1: {
          concepts: {
            phrase-id1: {word: phrase1, volume: x},
            phrase-id2: {word: phrase2, volume: y},
            phrase-id3: {word: phrase3, volume: z}
          }
        },

        Name2: {...}
      }	
    },

    entities: [candidates names]


    output
    ======
    {
      Name1: [
        {word: phrase1, volume: x normalized to 100},
        {word: phrase2, volume: y normalized to 100},
        {word: phrase3, volume: z normalized to 100}

     * Name1 itself will not be in array
     ** array sorted by name
     *** array max size = 5
      ],

      Name2: [...]
    }

*/

    let output = {};

    try {

      //find max volume
      var maxVolume = {
        volume: 0
      };
      candidates.forEach((candidate) => {

        let conceptsObj = results.data.bubbles[candidate].concepts;

        Object.keys(conceptsObj).forEach((key) => {

          let concept = conceptsObj[key];
          if (concept.word !== candidate &&
            maxVolume.volume < utils.round(concept.volume)) {
            maxVolume.volume = utils.round(concept.volume);
            maxVolume.word = concept.word;
          }
        });

      });

      output.maxVolume = maxVolume;


      candidates.forEach((candidate) => {

        let conceptsObj = results.data.bubbles[candidate].concepts;
        let concepts = [];

        //populate arrays
        Object.keys(conceptsObj).forEach((key) => {

          let concept = conceptsObj[key];

          if (concept.word !== candidate) {
            concepts.push({
              word: concept.word,
              volume: concept.volume,
              normalizedVolume: utils.round(100 * concept.volume / maxVolume.volume)
            });
          }
        });

        //sort by volume
        concepts = concepts.sort((concept1, concept2) => {
          let comparison = 0;
          if (concept1.volume < concept2.volume) {
            comparison = 1;
          } else if (concept1.volume > concept2.volume) {
            comparison = -1;
          }
          return comparison;
        });

        output[candidate] = $.extend(true, [], concepts);

        //max size = 5
        output[candidate].length = Math.min(output[candidate].length, 5);

      });

      if (utils.isDebug()) {
        console.log("bubbles converted", output)
      }

    } catch (er) {
      console.error(er);
      return null;
    }

    return output;
  }
}

export default BubblesData;
