/*convert data into geo map

how to get the map svg
======================
1. fetch a topojson file from BI.
   here we used: sg_electoral-divisions.topojson from BI 

2. convert it to SVG with 
   https://mapshaper.org/

3. use that software to edit / remove regions (click arrow > select-features > delete )

4. cut the svg <path>s into a file like geo-map.js

5. remove breaklines with http://removelinebreaks.net/ 

6. in a js file do:
	let Map = '<path...'
	export default Map;
*/


import $ from 'jquery';

import utils from './../../common/utils';
import sortBy from 'lodash/sortBy';

const GeoData = {

	convert: function (results, candidates) {

		/*
		response
		========
		
		data: {
			plot: {
				1: {
					values: [
						{
							score: float, 
							term: Name1
						},

						{
							score: float, 
							term: Name2
						},
						...
					],

					name: Electoral1
				},


				2: {
					values: [...],
					
					name: Electoral2
				}
			}
		},

		entities: [candidates names]


		output
		======

		1: {
			topCandidate: candidateX,
			topCandidateIndex: index in selected candidates OR -1 of not in selected candidates
			values: [...],
			name: Electoral1
		},

		2: {
			topCandidate: candidateY,
			topCandidateIndex: ...
			values: [...],
			name: Electoral2,
		}
		
		*/

		let output = {};

		try {

			output = $.extend(true, {}, results.data.plot);

			//sort values and set top candidate
			Object.keys(output).forEach((region) => {
				output[region].values = output[region].values.filter((val) => candidates.includes(val.term))
				output[region].values = sortBy(output[region].values, (val) => {
					return -val.score
				});
				output[region].topCandidate = output[region].values[0].term;
				output[region].topCandidateIndex = candidates.indexOf(output[region].topCandidate);
			})

			if (utils.isDebug()) {
				console.log("geo converted", output)
			}

		} catch (er) {
			console.error(er);
			return null;
		}

		return output;
	}
}

export default GeoData;
