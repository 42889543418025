import $ from 'jquery';
import './notificator.scss';

/*to use:
=========
Notificator.success(message)
Notificator.notify(message)
Notificator.error(message)
*/


let notificator = {
  create: function(str, cls){

    //create general notification
    if(0 === $(".notifications").length){
      $('body').append('<div class="notifications"></div>');
    }

    setTimeout(()=>this.createCB(str, cls));
  },

  createCB(str, cls){

    let prevStr = $('.notifications .notification').html() || '';
    if(prevStr.includes(str)){
      return; //dont show same message twice
    }

    let img = cls === 'error' ?
      '<img align="top" src="images/error.png"/> ' : '';

    this.domElm = $('.notifications').append(
      '<div class="notification pointer '+cls+'" '+
      'onClick = "this.remove()" '+
      '>' + img + str + ' <span class="close">x</span></div>');

    this.opacity=1;
    this.timer=6000;
    this.fade();
  },

  fade: function(){
    setTimeout(()=>{
      this.timer-=50;

      if(this.timer<=0){
        this.remove();
      }else{
        if(this.timer<1000){
          this.opacity-=0.01;
          this.opacity=Math.max(0, this.opacity)
          $(".notification").css("opacity", this.opacity);
        }
        this.fade();
      }
    },50);
  },

  remove: function(){
    this.domElm.remove();
  },

  notify: function(str){
    this.create(str, 'notify');
  },

  error : function(str){
    this.create(str, 'error');
  },

  success : function(str){
    this.create(str, 'success');
  }
};

export default notificator;
