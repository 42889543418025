import EngagementData from './../widgets/engagement/engagement-data';

const requestImageFile = require.context('../images/candidates', true, /.png|.svg$/);

let utils = {

  round: function (value, digits = 1) {
    let roundBy = Math.pow(10, digits);
    return Math.round(value * roundBy) / roundBy;
  },

  formatFracture: function (value, digits = 1) {
    let num = this.round(value, digits);
    if (-1 === (num + "").indexOf(".")) {
      num = num + '.0';
    }
    return num;
  },

  isDebug: function () {
    return window.location.search.includes("debug");
  },

  capitalize: function (str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  },

  candidateImage: function(can) {
    try {
      return requestImageFile(`./${can}.png`).default;
    } catch (e) {
      return requestImageFile('./user.svg').default;
    }
  },

  sortCandidates(candidates, consumption) {

    //sort by engagament

    if (!consumption) {
      return candidates;
    }

    let candidatesEngagement = [];
    candidates.forEach((can) => {
      let allEngagement = EngagementData.convert(consumption, can);
      let engagament = allEngagement ? allEngagement[can] : 0
      candidatesEngagement.push({
        name: can,
        eng: engagament
      });
    })

    let sortedCandidates = candidatesEngagement.sort((can1, can2) => {
      return can2.eng - can1.eng;
    });

    return sortedCandidates.map((can) => can.name);
  },

  candidateName(name) {
    switch (name) {
      case "People's Action Party":
        return 'PAP';
      case "Progress Singapore Party":
        return 'PSP';
      case "Workers' Party":
        return 'WP';
      case "Singapore Democratic Party":
        return 'SDP';
      case "Iswaran":
        return "S. Iswaran";
      default:
        return name;
    }
  }
}

export default utils;
