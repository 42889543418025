import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Login from './components/login/login';
import LoginNotAuthorized from './components/login/login-not-authorized';
import LoginLoading from './components/login/login-loading';
import MainPanel from './main-panel/main-panel';

import './style/style.scss';

const App = ()=>{
  const [authroized, setAuthroized] = useState(undefined);

  useEffect(() => {
    if(authroized === undefined){
      Login.checkToken(
        ()=>setAuthroized(true),
        ()=>setAuthroized(false)
      )
    }
  }, [authroized]);

  return authroized === undefined ? <LoginLoading/> :
    authroized === false ? <LoginNotAuthorized/> :
    <MainPanel/>
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render( <App/>, document.getElementById('app'));
});
