import React from 'react';

import './login-not-authorized.scss';

const LoginNotAuthorized = () => {
  return (
    <div className="login-not-authorized">
      <div className="box">
        <img className="logo" alt="Amobee" src="./images/amobee-logo.png"/>

        <div className="welcome">
          Welcome To Amobee Dashboard Intelligence
        </div>

        Your account is not authorized for this app. <br/>
        Please contact your account manager today.
      </div>
    </div>
  )
};

export default LoginNotAuthorized;
