import moment from 'moment';
import env from './env';
import merge from 'lodash/merge';

let constants = merge(env, {
  MAX_ENTITIES: 6,
  MINIMUM_FORCED_DATE: 'March 31, 2020',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT_DISPLAY: 'DD.MM.YYYY',
  MAX_DAYS: 180,
  DAYS_SPAN: 7,
  CLIENT_ID: 'WRaO1Z76PGEACUBXbSXJ',
  STANDARD_ERROR: 'Sorry, something went wrong. Our engineers are working quickly to resolve the issue.',

  queries: {
    'consumption': null,
    'channels': null,
    'bubbles': null,
    'geo': null,
    'audience': null
  },

  PUBLISHERS_COLORS: {
    mainstream: '#69CEB2',
    'alternative / forum': '#EC7778',
    foreign: '#858585'
  },

  PUBLISHERS_COLORS_HOVER: {
    mainstream: '#56B49A',
    'alternative / forum': '#EA6061',
    foreign: '#808080'
  },

  getDefaultDataset: function() {
    return{
      end: moment.utc().format(this.DATE_FORMAT),
      start: moment.utc().subtract(this.DAYS_SPAN, 'days').format(this.DATE_FORMAT) 
    }
  }
});

export default constants;
