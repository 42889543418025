//convert data into audience skew blocks

import $ from 'jquery';

import utils from './../../common/utils';

const AudienceData = {

	convert: function (results, candidates, dataset) {

		/*
		results - example for 2 names & 3 associations
		=======
		
		result_date: YYYY-MM-DD

		data: {
			Name1: {
				general: { like male/female },

				female: {
					status: ok / error,
					data: {

						race-skew: {
							indian: float
							malay: float
							other: float
							chinese: float
						},

						age-skew: {
							12-17: float
							18-24: float
							25-34: float
							35-44: float
							45-54: float
							55-64: float
							65+: float
						},

						gender-skew: {
							male: float
							female: float
						}
					}
				},

				male  : {...}
			},
	
			
			Name2: {...}
		},

		entities: [candidates names]



		output
		======
		
		Name1: {
			female: {
				general: { },

				data: {

					race-skew: {
						indian: float
						malay: float
						other: float
						chinese: float
					},

					race-skew-level: {
						indian: 0-6
						malay: 0-6
						other: 0-6
						chinese: 0-6
					},


					age-skew: {
						12-17: float
						18-24: float
						25-34: float
						35-44: float
						45-54: float
						55-64: float
						65+: float
					},

					age-skew-by-gender-skew: {
						12-17: float * gender-skew[gender]
						18-24: float * gender-skew[gender]
						25-34: float * gender-skew[gender]
						35-44: float * gender-skew[gender]
						45-54: float * gender-skew[gender]
						55-64: float * gender-skew[gender]
						65+: float
					},

					age-skew-by-gender-level: {...},


					gender-skew: {
						male: float
						female: float
					},

					gender-skew-level: {}
				}
			},

		Name2: {...},

		races: ['chinese', 'indian', ...] (sorted by ABC)
		ages: ['12-17', '18-24', ...]
		genders: ['female', 'male'],

		*** please note
		===============
		1. race,gender from general data

		2. age is from male/female data. 
		   if they are empty, get it from general data
		
		*/

		let output = null,
		minSkew = 100,
		maxSkew = 0,
		skewLevels = [];

		try {

			//get arrays of all options from 1st candidate
			let data = {};
			const allCandidates = Object.keys(results.data);
			for (let candidate of allCandidates) {
				if (results.data[candidate].general.data) {
					data =  results.data[candidate].general.data;
					break;
				}
			}
			let races = Object.keys(data['race-skew']);
			let ages = Object.keys(data['age-skew']);
			let genders = Object.keys(data['gender-skew']);

			//age-skew = age-skew: * gender-skew[gender]
			candidates.forEach((can) => {

				let data = results.data[can];

				genders.forEach((gen) => {

					let genderData = this.getGenderData(data, gen);
					let generalData = data.general.data;

					if (genderData) {
						genderData['age-skew-by-gender'] = {};

						Object.keys(genderData['age-skew']).forEach((key) => {
							genderData['age-skew-by-gender'][key] =
								genderData['age-skew'][key] * generalData['gender-skew'][gen];
						});
					}
				});
			});

			//get min+max of ALL skews
			candidates.forEach((can) => {

				let data = results.data[can];

				genders.forEach((gen) => {

					let genderData = this.getGenderData(data, gen);
					let generalData = data.general.data;

					if (genderData) {
						Object.keys(genderData['age-skew-by-gender']).forEach((key) => {
							maxSkew = Math.max(maxSkew, genderData['age-skew-by-gender'][key]);
							minSkew = Math.min(minSkew, genderData['age-skew-by-gender'][key]);
						});

						Object.keys(generalData['race-skew']).forEach((key) => {
							maxSkew = Math.max(maxSkew, generalData['race-skew'][key]);
							minSkew = Math.min(minSkew, generalData['race-skew'][key]);
						});

						Object.keys(generalData['gender-skew']).forEach((key) => {
							maxSkew = Math.max(maxSkew, generalData['gender-skew'][key]);
							minSkew = Math.min(minSkew, generalData['gender-skew'][key]);
						});
					}
				})

			});


			//set skew levels range
			skewLevels = getSkewLevels(minSkew, maxSkew);

			//set skew level per item
			candidates.forEach((can) => {

				let data = results.data[can];

				genders.forEach((gen) => {

					let genderData = this.getGenderData(data, gen);
					let generalData = data.general.data;

					if(genderData && generalData) {

						genderData['age-skew-by-gender-level'] = {};
						generalData['race-skew-level'] = {};
						generalData['gender-skew-level'] = {};

						Object.keys(genderData['age-skew-by-gender']).forEach((key) => {
							genderData['age-skew-by-gender-level'][key] =
								getSkewLevel(genderData['age-skew-by-gender'][key]);
						});

						Object.keys(generalData['race-skew']).forEach((key) => {
							generalData['race-skew-level'][key] =
								getSkewLevel(generalData['race-skew'][key]);
						});

						Object.keys(generalData['gender-skew']).forEach((key) => {
							generalData['gender-skew-level'][key] =
								getSkewLevel(generalData['gender-skew'][key]);
						});
					}
				})
			});

			output = $.extend(true, {}, results.data);

			output.skewLevels = skewLevels;
			output.races = races.sort();
			output.ages = ages;
			output.genders = genders;

			if (utils.isDebug()) {
				console.log("audience converted", output)
			}

		} catch (er) {
			console.error(er);
			return null;
		}

		return output;

		function getSkewLevels(min, max) {
			/*
				divides all skews to:

				three levels between min and 1 ,
				1 ,
				three levels between 1 and max

				example:
				========
				min = 0, max = 10, levels =

				level 0 : 0   - 1/6
				level 1 : 1/6 - 1/2
				level 2 : 1/2 - 5/6
				level 3 : 5/6 - 2.5
				level 4 : 2.5 - 5.5
				level 5 : 5.5 - 8.5
				level 6 : 8.5 - 10
			*/

			let levels = [{
					level: 0,
					start: min,
					end: min + (1 - min) * 1 / 6
				},

				{
					level: 1,
					start: min + (1 - min) * 1 / 6,
					end: min + (1 - min) * 3 / 6
				},

				{
					level: 2,
					start: min + (1 - min) * 3 / 6,
					end: min + (1 - min) * 5 / 6
				},

				{
					level: 3,
					start: min + (1 - min) * 5 / 6,
					end: 1 + (max - 1) * 1 / 6
				},

				{
					level: 4,
					start: 1 + (max - 1) * 1 / 6,
					end: 1 + (max - 1) * 3 / 6
				},

				{
					level: 5,
					start: 1 + (max - 1) * 3 / 6,
					end: 1 + (max - 1) * 5 / 6
				},

				{
					level: 6,
					start: 1 + (max - 1) * 5 / 6,
					end: max
				}
			];

			levels = levels.map((skewLevel) => {
				return {
					level: skewLevel.level,
					start: utils.round(skewLevel.start),
					end: utils.round(skewLevel.end)
				}
			})
			return levels;
		}

		function getSkewLevel(num) {
			//return skew level 0-6
			//getSkewLevels has exact calculation

			num = utils.round(num);

			const lastSkewLevel = skewLevels.length - 1;
			if (num >= skewLevels[lastSkewLevel].end) {
				return lastSkewLevel;
			}
			const skewLevel = skewLevels.find(element => num >= element.start && num <= element.end);
			return skewLevel.level;
		}

	},

	getGenderData: function (data, gender) {
		//if gender data isnt found, 
		//fetch it from general data

		return data[gender].data ?
			data[gender].data :
			data.general.data;
	}
}

export default AudienceData;
