import React, {useState, useEffect, useCallback} from 'react';
import {isEmpty} from 'lodash';
import ReactTooltip from 'react-tooltip'
import {getToggleDatasetState, getToggleCandidateState} from './main-panel-state';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Selection from '../components/selection/selection';
import Cards from '../widgets/cards/cards';
import Consumption from '../widgets/consumption/consumption';
import Bubbles from '../widgets/bubbles/bubbles';
import Geo from '../widgets/geo/geo';
import Audience from '../widgets/audience/audience';
import constants from '../common/constants';
import LoginLoading from '../components/login/login-loading';

//style
import '../style/style.scss';

const MainPanel = () => {

  const [isLandscape, setIsLandscape] = useState(false);

  const [state, setState] = useState({
      allCandidates: [],
      candidates: [],
      results: null
  });

  const toggleDataset  = useCallback(
    async(dataset) => {
      const newState = await getToggleDatasetState({dataset, state});
      setState(newState);
    },
    [state, setState]
  );

  useEffect(() => {
    !state.results && toggleDataset(constants.getDefaultDataset());
    ReactTooltip.rebuild();
  }, [state.results, toggleDataset, state.candidates, state.dataset]);

  const toggleCandidate = (candidate)=>{
    setState(getToggleCandidateState({state, candidate}));
  }

  return (!state.results ? <LoginLoading /> :
    <div className = {isLandscape ? 'landscape' : ''}>
      <Header isLandscape = {isLandscape} setIsLandscape = {()=>setIsLandscape(!isLandscape)}/>
      <div className="page-center">

        <Selection
          candidates={state.candidates}
          allCandidates={state.allCandidates}
          toggleCandidate = {(can)=>toggleCandidate(can)}
          toggleDataset = {(dataset)=>toggleDataset(dataset)}
        ></Selection>

        <Cards
           candidates={state.candidates}
           consumptionResults={state.results.consumption}
           channelsResults={state.results.channels}
        ></Cards>


        {
          !isEmpty(state.results) &&
             <div className="page-grid">
               <Consumption 
                  candidates={state.candidates}
                  results={state.results.consumption}/>

               <Geo
                  candidates={state.candidates}
                  results={state.results.geo}/>

               <hr className="page-grid-hr" style={{gridColumn: 'span 2', margin: '28px 10px'}}/>
               <hr className="page-grid-hr" style={{'display': 'none'}}/>

               <Audience
                  candidates={state.candidates}
                  results={state.results.audience}
                  dataset = {state.dataset}/>

               <Bubbles
                  candidates={state.candidates}
                  results={state.results.bubbles}/>
             </div>
        }

        <Footer/>

      </div>

    <ReactTooltip multiline = {true} />

    </div>
  )
};

export default MainPanel;
