const getEnv = () => {
  const host = new URL(window.location).host;
  if (host.startsWith("localhost")) return "localhost";

  const env = host.split(".intelligence.amobee.com")[0].split(/\.|-/)[1];
  return env;
}

const getEnvLocation = (env, subdomain, localURL, useGateway = true) => {
  if (env === "localhost" && localURL) return localURL;
  const envdot = (env !== "localhost" && env !== undefined) ? env + '.' : '';

  return useGateway ? `https://sap.${envdot}intelligence.amobee.com/${subdomain}` : `//${subdomain}.${envdot}intelligence.amobee.com`;
}

const getEnvVariables = (env) => {
  return {
    API: getEnvLocation(env, "dashboards-api", process.env.REACT_APP_API_URL, false),
    LOGIN: getEnvLocation(env, "login", process.env.REACT_APP_LOGIN_URL, false)
  }
}

const environment = getEnvVariables(getEnv());
export default environment;
