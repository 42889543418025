//convert data into consumption lines chart

import moment from 'moment';

import './../../style/style.scss';
import utils from './../../common/utils';

const ConsumptionData = {

	convert: function (results, candidates, options, datasetsOptions) {

		/*
		response - example for 2 names & 3 dates
		========
		
		data: {
			Name1: {
				datapoints: [
					{date: YYYY-MM-DD, value:...},
					{date: YYYY-MM-DD, value:...},
					{date: YYYY-MM-DD, value:...}
				]
			},
			
			{
				Name2: {...}
			}
		},

		entities: [candidates names]


		output
		======
		{
			data: {
				labels:[DD.MM, DD.MM, DD.MM],
				
				datasets: [
					{data: [val1, val2, val3], label: Name1, ...datasetsOptions(0)},
					{data: [val1, val2, val3], label: Name2, ...datasetsOptions(1)}
				]
			},

			options: options
		}

		*/

		let datasets = [],
			labels = [],
			output;

		try {

			//datasets
			candidates.forEach((can, index) => {
				let dataset = {
					data: results.data[can].datapoints.map((point) => utils.round(point.value)),
					label: can,
					...datasetsOptions(index)
				}

				dataset.data = dataset.data.reverse();

				if (index === 0) {
					//labels = [dates];
					results.data[can].datapoints.forEach((point) => {
						labels.push(moment(point.date).format("DD.MM"));
					});

					labels = labels.reverse();
				}

				datasets.push(dataset);
			});

			output = {
				data: {
					datasets,
					labels
				},
				options: options
			};

			if (utils.isDebug()) {
				console.log("consumption converted", output)
			}

		} catch (er) {
			console.error(er);
			return null;
		}

		return output;
	}
}

export default ConsumptionData;
