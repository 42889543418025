import React from 'react';
import Icon from '../icon/icon';
import './widget-error.scss';

const WidgetError = () => (
	<div className="widget-error">
		<div className="inner-error">
			<Icon iconId="widget-error" width="32px" height="32px"/> 
			<div className="error-title">Sorry, something went wrong</div>
				Our engineers are working quickly<br/>to resolve the issue.
			</div>
  	</div>
);

export default WidgetError;