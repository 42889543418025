import React from 'react';
import {Line} from 'react-chartjs-2';
import constants  from './../../common/constants';
import ConsumptionData from './consumption-data';
import utils from './../../common/utils';
import WidgetError from './../../components/widget-error/widget-error';
import styleConstants from './../../style/style-constants.module.scss';
import './consumption.scss';


const Consumption = ({results, candidates})=>{

    let trends = ConsumptionData.convert(results, candidates, options, datasetsOptions);

    if(!trends){
      return <WidgetError/>
    }

    return <div className="consumption grid-item">
      <div className="title">Entity Consumption over Time</div>
    	<div className="info">Digital content consumption with each entity over time</div>

    	<div className="labels">
        {trends.data.datasets.map((data, i)=>
          <span className="rect-with-name" key={i}>
           
            <div className="rect" style={{
            backgroundColor: styleConstants[data.color]
            }}></div><span className="info">{utils.candidateName(data.label)}</span>
            
            {i+1 === constants.MAX_ENTITIES/2 ? <br/> : null}
            </span>
          )}
      </div>
      
      <div className="chart-wrap">
        {
          trends.data.datasets.length ? 
            <Line data={trends.data} options={trends.options}/> : null
        }
      </div>

    </div>

};

//chart.js options
const options = {  
  legend: {
    display: false
  },
  tooltips: {  
   
    callbacks: {

      title: function(tooltipItem, data) {
        return '';
      },
      label: function(tooltipItem, data){
        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        return utils.candidateName(label) +' '+tooltipItem.yLabel;
      }
      
    }
  },
    elements: {point:{hitRadius: 30}
  },

  aspectRatio: 3,
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    xAxes: [{
        gridLines: {
            display:false
        }   
    }],

    yAxes: [{
        ticks: {
            beginAtZero:true
        }
    }]
  }
};

function datasetsOptions(index){
  //chart.js options inside dataset
  
  let color = 'candidate'+index;
  let options = {
    borderColor: styleConstants[color],
    fill: false,
    pointBorderColor: "transparent",
    pointBackgroundColor: styleConstants[color],
    pointStyle: "line",
    index: index,
    color: "candidate"+index,
    borderWidth: 3
  };

  return options;
}

export default Consumption;
