import React from 'react';
import Engagement from './../engagement/engagement';
import Channels from './../channels/channels';
import constants  from './../../common/constants';
import utils from './../../common/utils';
import WidgetError from './../../components/widget-error/widget-error';
import ChannelsData from '../channels/channels-data';
import EngagementData from '../engagement/engagement-data';
import {options, datasetOptions} from '../channels/channels-options';
import './cards.scss';

const Cards = ({candidates, consumptionResults, channelsResults})=> {

    if(!candidates.length || !consumptionResults || !channelsResults){
      return <WidgetError/>
    }

    let hasError = false, channelsOutput = {}, engagementOutput = {};
    for(let candidate of candidates){
      channelsOutput[candidate]   = ChannelsData.convert(channelsResults, candidate, options, datasetOptions);
      engagementOutput[candidate] = EngagementData.convert(consumptionResults, candidate);
      if(!channelsOutput[candidate] || !engagementOutput[candidate]){
        hasError = true;
        break;
      }
    };

    if(hasError){
      return <WidgetError/>
    }

    
    return <div className="cards-wrap">
      <div className="cards">

      {
        candidates.map((can, i)=>(
          <Card can = {can} 
                key = {i} 
                engagementOutput = {engagementOutput[can]}
                channelsOutput = {channelsOutput[can]}/>
        )) 
      }  
      </div>

      <div className="color-map">
        <b>Publisher Types:</b>

        {
          Object.keys(constants.PUBLISHERS_COLORS).map((key)=>(
             <span className="rect-with-name" key = {key}>
              <div className="rect" style={{background: constants.PUBLISHERS_COLORS[key]}}></div> 
                {key}
             </span>
          ))
        }
      </div>
      <hr/>
    </div>
}

const Card = ({engagementOutput, channelsOutput, can})=> {

  return <div className="card">

    <div className="name">{utils.candidateName(can)}</div>
    <img className="face" alt={utils.candidateName(can)} src={utils.candidateImage(can)} />

    <Engagement output = {engagementOutput} candidate = {can}></Engagement>
    <Channels output = {channelsOutput}></Channels>

  </div> 
}

export default Cards;
