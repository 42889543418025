import moment from 'moment';
import {difference} from 'lodash';
import utils from '../common/utils';
import axios from 'axios';
import constants from '../common/constants';
import Notificator from '../components/notificator/notificator';

export async function getToggleDatasetState({dataset, state, failedOnce}){
    let newState = {...state, results : {}};

    if(utils.isDebug()){
      console.log("dataset", moment(dataset.start).format(constants.DATE_FORMAT_DISPLAY)+" - "+moment(dataset.end).format(constants.DATE_FORMAT_DISPLAY));
      console.log("===============================");
    }
    let queries = Object.keys(constants.queries).map(queryID=>
      axios.get(constants.API+"/"+ queryID +"?date="+dataset.end).then(response=>{
        return {results: response.data, queryID}
      }).catch(er=>{
        console.error(er);
        return null;
      })
    );

    
    const responses = await Promise.all(queries);

    let allCandidates = null;

    for(let response of responses){
      if(!isValidResponse(response)){
        const failedQueriesState = await getFailedQueriesState({dataset, state: newState, failedOnce});
        return failedQueriesState;
      }

      let resultsCandidates = response.results.entities;

      if(!allCandidates){
        allCandidates = resultsCandidates; 
      }else if(!isValidCandidates({resultsCandidates, allCandidates})){
        const failedQueriesState = await getFailedQueriesState({dataset, state: newState, failedOnce});
        return failedQueriesState;
      }

      newState.results[response.queryID] = response.results;   
    };

    return {...newState, 
      candidates : getCandidatesFromResults({state:newState, allCandidates}), 
      allCandidates
    }
}

const getFailedQueriesState = async ({dataset, state, failedOnce})=>{
  let newState = {...state};

  if(failedOnce){//if failed for 2nd+ time, error
    console.error('Error fetching dashboard queries');  
    return getErrorState(newState);
  }else{ //if failed for 1st time, try another date (-1)

    if(moment().subtract(constants.MAX_DAYS, 'days').isSame(moment(dataset.end2), 'day')){
      return getErrorState(newState);//dont go further than MAX_DAYS
    }

    dataset = {
      start: moment(dataset.start).subtract(1, "days").format(constants.DATE_FORMAT),
      end: moment(dataset.end).subtract(1, "days").format(constants.DATE_FORMAT)
    }
    const prevDayState = await getToggleDatasetState({dataset, state: newState, failedOnce: true});
    return prevDayState;
  }
}


export const getToggleCandidateState =({state, candidate})=>{
    
  let candidates = [...state.candidates];
  let maxCandidatesWarning = false;

  if(candidates.includes(candidate)){
    if(candidates.length>1){
      candidates = candidates.filter((c)=>c !== candidate);
    }else{
      maxCandidatesWarning = true;
    }
  }else{
    if(candidates.length>=constants.MAX_ENTITIES){
      maxCandidatesWarning = true;
    }else{
      candidates.push(candidate);
    }
  }

  if(maxCandidatesWarning){
    Notificator.notify('please select up to '+constants.MAX_ENTITIES+' entities');
  }

  return {...state, candidates: utils.sortCandidates(candidates, state.results.consumption)} 
}

const getCandidatesFromResults =({state, allCandidates})=>{
    let candidates = state.candidates.length ? state.candidates : allCandidates || [];
    candidates = candidates.filter((can)=>allCandidates.includes(can));
    candidates = utils.sortCandidates(candidates, state.results.consumption);
    candidates.length = Math.min(candidates.length, constants.MAX_ENTITIES);
    return candidates;
}

export const isValidResponse = (response)=>{
  if(!response || response.error){
    return false;
  }
  const results = response.results;
  return !! (results && 
            Object.keys(results).length && 
            results.data &&
            Array.isArray(results.entities) &&
            results.entities.length);
}

export const isValidCandidates =({resultsCandidates, allCandidates})=>{
  return difference(resultsCandidates, allCandidates).length === 0 &&
         difference(allCandidates, resultsCandidates).length === 0;
}

export const getErrorState =(state)=>{
  return {...state, results: {}}
}
