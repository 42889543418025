import React from 'react';

import utils from './../../common/utils';
import BubblesData from './bubbles-data';
import styleConstants from './../../style/style-constants.module.scss';
import WidgetError from './../../components/widget-error/widget-error';
import './bubbles.scss';

const Bubbles = ({results, candidates}) => {

    let ass = BubblesData.convert(results, candidates);

    if(!ass){
        return <WidgetError/>;
    }

    return <div className="bubbles grid-item">
          <div className="title">Top Trending Entity Associations</div>
          <div className="info">Trending entity associations with the highest consumption volume</div>

          {
            candidates.map((can, i)=>{
              return <table key = {i} className="candidate">
                <tbody>
                  <tr><td className={"bubbles-cloud " + (i===candidates.length-1 ? "last" : "")}>{bubblesCloud(ass, can)}</td>
                    <td><img className="face-small" alt={utils.candidateName(can)} src={utils.candidateImage(can)} data-tip = {utils.candidateName(can)} /></td>
                  </tr>
                </tbody>
              </table>
            })
          }
      <br/>
    </div>
};


const bubblesCloud = (ass, can)=>{

    if(!ass[can].length){
      return 'Sorry, insufficient content about '+can;
    }

    return ass[can].map((a, i)=>{

          let fontSize = utils.round(a.normalizedVolume/2);
          fontSize = Math.min(fontSize,25);
          fontSize = Math.max(fontSize,11);

          return <span key = {i}
          className="phrase"
          data-tip={"volume "+utils.round(a.normalizedVolume)}
          style={{
            marginRight: 7,
            position: 'relative',
            fontSize: fontSize,
            color: styleConstants[can['party affiliation']]}}>
            {a.word}

          </span>
        })
  }

export default Bubbles;
