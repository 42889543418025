import constants  from './../../common/constants';


//chart.js style options inside dataset
export const options = {
  legend: false, 
  tooltips: {
    bodyFontSize: 20,

    callbacks: {
      label: function(tooltipItem, data) {

        var dataset = data.datasets[tooltipItem.datasetIndex];
        var currentValue = dataset.data[tooltipItem.index];
        var label = data.labels[tooltipItem.index];
        return " "+label + " "+currentValue+"%";
      }
    }
  },
  responsive:false,
  cutoutPercentage:90
}

//chart.js style options
export const datasetOptions = {

	backgroundColor: Object.keys(constants.PUBLISHERS_COLORS).map((key)=>constants.PUBLISHERS_COLORS[key]),
 
	hoverBackgroundColor: Object.keys(constants.PUBLISHERS_COLORS_HOVER).map((key)=>constants.PUBLISHERS_COLORS_HOVER[key]),

  borderWidth: 0
}