import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import './channels.scss';

const Channels = ({output}) => {

  //parent (Cards) handles errors
  return output && <div className="channels">
     <Doughnut data={output.data} options={output.options}/>
  </div>
};

export default Channels;
