import React from 'react';
import './checkbox.scss';
import Icon from '../icon/icon';
import classNames from 'classnames';

const Checkbox = ({ label, isDisabled, onToggle, isSelected, id, that, children}) => (
  <div className={ classNames('checkbox-component', { disabled: isDisabled }) } onClick={ () => !isDisabled && onToggle(id, that) }>
    <Icon iconId={ isSelected ? 'selected-checkbox' : 'unselected-checkbox' } 
      className={ classNames('checkbox-icon', { 'selected-checkbox-icon': isSelected }) }></Icon>
    { label }
    { children }
  </div>
);

export default Checkbox;