/*login
=======

1. check if user has localStorage.internal_access_token
and it didnt expire

2. if not, goto:
     login.intelligence.amobee.com/?redirect=<current url>

3. after login, login-app will redirect to <current url>#/internal_token=...

4. check internal_access_token:
  if not authorized, redirect to 
  login-not-authorized

5. localStorage.internal_access_token = internal_access_token

6. remove okta fragments from url

*/
import axios from 'axios';
import constants from './../../common/constants';
import jwt_decode from 'jwt-decode';

const Login  = {

  checkToken: (cb, notAuthorizedCB)=>{
    let internal_access_token = getToken('internal_access_token');
    let recoveryToken = getToken('recoveryToken');
    let status = tokenStatus(internal_access_token, window.location.href);
    if("verified" !== status){
      console.log("login status: "+status);
    }

    removeURLFragments();

    if("verified" === status){
      localStorage.internal_access_token = internal_access_token;
      axios.interceptors.request.use((config)=> {
        config.headers.Authorization =  internal_access_token;
        return config;
      });

      cb();
    }else if("not authorized" === status || 
      "not issued by us" === status){
      notAuthorizedCB();
    }else if("forgot password" === status){
      //redirect to forgot password
      let redirectUrl = window.location.href.replace("/forgot_password", "");
      redirectUrl = redirectUrl.split('?')[0] 

      window.location.href = constants.LOGIN+
        '/?recoveryToken='+recoveryToken+
        '&client_id='+constants.CLIENT_ID+
        '&redirect='+redirectUrl;
    }else{
      //redirect to login
      window.location.href = constants.LOGIN+
        '/?client_id='+constants.CLIENT_ID+
        '&redirect='+window.location.href;
    }
  }
}

function getToken(TOKEN_NAME){

  //from window.location.href... internal_access_token
  //OR localStorage.internal_access_token

  let token = '';

  if(window.location.href.includes(TOKEN_NAME)){	
    let str = window.location.href;
    str = str.substring(str.indexOf(TOKEN_NAME+"=")+TOKEN_NAME.length+1);

    let indexOfAND = str.indexOf("&") > -1 ? str.indexOf("&") : str.length;
    token = str.substring(0, indexOfAND);
  }else{
    token = localStorage[TOKEN_NAME];
  }

  return token;
}

function removeURLFragments(){
  window.location.hash = '';
}

function tokenStatus(token, path){

  if(path.includes('forgot_password')){
    return 'forgot password';
  }

  if(!token){
    return 'no token';
  }

  var jwt = jwt_decode(token);

  let currentDate = Math.round(Date.now() / 1000); //seconds

  if(jwt.exp < currentDate){
    return 'expired';
  }

  if(jwt.iss !== "com.kona.auth.v0"){
    return 'not issued by us';
  }

  if(false === jwt.authorities.includes("APP_BI_DASHBOARDS")){
    return 'not authorized';
  }

  return 'verified';
}

export default Login;
