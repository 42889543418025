import React from 'react';
import sprite from '../../images/sprite.svg';

const Icon = ({ iconId, className, height='16px', width='16px'}) => (
  <svg className={ "icon " + className } style={ { height, width } }>
    <use href={ `${sprite}#${iconId}` } style={ { fill: 'currentColor', ':focus': { outline: 'none' } } }></use>
  </svg>
);

export default Icon;
