//convert data into engagement bar

import utils from './../../common/utils';

const EngagementData = {

	convert: function (results, candidate) {

		/*
		response - example for 2 names & 3 dates
		========
		
		data: {
				Name1: {
					datapoints: [
						{date: YYYY-MM-DD, value:...},
						{date: YYYY-MM-DD, value:...},
						{date: YYYY-MM-DD, value:...}
					]
				}
			},

			{
				Name2: {...}
			}
		},

		entities: [candidates names]



		output - 1 candidate
		======
		{
			value: <avarage of datapoints values of 1 candidate>	
		}
		*/

		let data,
			sum,
			average,
			output;

		try {

			//average of all data values
			data = results.data[candidate].datapoints.map((point) => point.value);
			sum = data.reduce((sum, val) => sum + val);
			average = sum / data.length;

			output = {
				[candidate]: utils.round(average)
			}

			if (utils.isDebug()) {
				console.log("engagement converted", output)
			}

		} catch (er) {
			console.error(er);
			return null;
		}

		return output;
	}
}

export default EngagementData;
