import React from 'react';

import utils from './../../common/utils';
import constants  from './../../common/constants';
import GeoData from './geo-data';
import styleConstants from './../../style/style-constants.module.scss';
import WidgetError from './../../components/widget-error/widget-error';
import GeoMap from './geo-map';
import './geo.scss';

const Geo = ({results, candidates}) => {

    let data, pathes = [];

    data = GeoData.convert(results, candidates);
    pathes = getMapPatehs(GeoMap, data);
    
    if(!data){
      return <WidgetError/>
    }

    return <div className="geo grid-item">
        <div className="title">Entity Consumption by Geo</div>
        <div className="info">Break down of ward-level consumption with entities</div>
    	
    	<div className="labels">
	        {candidates.map((can, i)=>
	          <span className="rect-with-name" key={i}>
	           
	            <div className="rect" style={{
	            backgroundColor: styleConstants["candidate"+i]
	            }}></div><span className="info">{utils.candidateName(can)}</span>
	            
	            {i+1 === constants.MAX_ENTITIES/2 ? <br/> : null}
	            </span>
	          )}
	      </div>

        <svg id="map" xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" viewBox="0 0 800 516"
            dangerouslySetInnerHTML={{__html: pathes}}>
        </svg>
    	
    </div>
};


const getMapPatehs = (GeoMap, data)=>{

    if(!data){
      return null;
    }

    let pathes = GeoMap.split("path");
    //path = <path d="M ... Z" id="id_..."/>

    pathes = pathes.map((path, i)=>{
     
       if(path.length<2){
        return '';
       }

       //id ="id_x"
       let id = path.substring(path.indexOf("id_")+3, path.indexOf("/")-1),
           region = data[id],
           fill = styleConstants["candidate"+region.topCandidateIndex] || '#f3f3f3';


       path = 'path fill="'+fill+'" '+path;
       path = path.substring(0,path.indexOf("/>"))+

        'data-tip="'+
        region.name+'<br/>'+
        utils.candidateName(region.topCandidate)+'<br/>'+
        utils.formatFracture(region.values[0].score)+
        '"'+

       '></path>';
      
       return '<'+path;
    });

    return pathes.join();
}
    

export default Geo;
