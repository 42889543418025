import React from 'react';
import utils from './../../common/utils';
import Icon from './../../components/icon/icon';
import AudienceData from './audience-data';
import WidgetError from './../../components/widget-error/widget-error';
import './audience.scss';

const AudienceCandidates = ({candidates, data}) => {

    let candidatesNum = [0, 1, 2, 3, 4, 5]; // to show male/female icons
    let candidatesLength = Math.min(candidatesNum.length, candidates.length);

    return <div>

        <div className="grid">

            <div className="first-item">
                Skew<br/>

                {
                    data.skewLevels.map((skew, i) =>

                        <div key={i} className={"skew-rect skew" + i}
                             data-tip={skew.start === skew.end ? "skew = " + utils.formatFracture(skew.end) :
                                 (utils.formatFracture(skew.start) + " <" +
                                     " skew " + (i === data.skewLevels.length - 2 ? '<' : '<=') + utils.formatFracture(skew.end))}>
                        </div>
                    )
                }

                <br/>
                <div className="info inline-block">Low</div>
                <div className="info inline-block right">High &nbsp; &nbsp; &nbsp; </div>
            </div>


            {
                candidates.map((can, i) => (
                    <div key={i}>
                        <img className="face-small" alt={utils.candidateName(can)} src={utils.candidateImage(can)}
                             data-tip={utils.candidateName(can)}/>
                    </div>
                ))
            }
        </div>

        <div className="grid">

            <div className="first-item"></div>

            {
                candidatesNum.map((can, i) => (
                    <div key={i} className={i >= candidatesLength ? "hide" : ""}>
                        <Icon iconId="male" className="gender-icon"/>
                        <Icon iconId="female" className="gender-icon"/>
                    </div>
                ))
            }
        </div>

    </div>
}


const AudienceGender = ({candidates, data}) => {

    /*
      Name1: {
          female: {
              data: {
                  gender-skew: {
                      male: float
                      female: float
                  }
                  */

    const getGenderSkew = (can, gender, data) => {

        //gender skew is from "general" section,
        //NOT from male/female

        let generalData = data[can].general.data;
        return utils.formatFracture(generalData['gender-skew'][gender]);
    }

    const getGenderSkewLevel = (can, gender, data) => {

        let generalData = data[can].general.data;
        return generalData['gender-skew-level'][gender];
    }


    return <div className="grid">

        <div className="info first-item">
            Gender
        </div>

        {
            candidates.map((can, i) => (

                <div key={i}>
                    {
                        data.genders.map((gen) => (
                            data[can] && data[can].general && data[can].general.status !== "error" ?
                                <div key={i + gen}
                                     className={"skew-rect medium skew" + getGenderSkewLevel(can, gen, data)}
                                     data-tip={utils.candidateName(can) + "<br/>" + utils.capitalize(gen) + "<br/>" + utils.formatFracture(getGenderSkew(can, gen, data))}>
                                </div> : <div key = {i + gen}
                                              className={"skew-rect medium no-data"}
                                              data-tip = {utils.candidateName(can) + "<br/>" + "Audience is too narrow"}>
                                </div>
                        ))
                    }
                </div>

            ))
        }

        <hr style={{'gridColumn': 'span 7'}}/>

    </div>;
};


const AudienceAge = ({candidates, data, age}) => {

    /*data for 1 age group

      Name1: {
          female: {
              data: {
                   age-skew: {
                      12-17: float
                      18-24: float
                      ...
                  },
                  */

    const getAgeSkew = (can, gender, age, data) => {

        let genderData = AudienceData.getGenderData(data[can], gender);

        return utils.formatFracture(genderData['age-skew-by-gender'][age]);
    };

    const getAgeSkewLevel = (can, gender, age, data) => {

        let genderData = AudienceData.getGenderData(data[can], gender)

        return genderData['age-skew-by-gender-level'][age];
    };


    return <div className="grid">

        <div className="info first-item">
            {ageDisplay(age)}
        </div>

        {
            candidates.map((can, i) => (

                <div key={i}>
                    {
                        data.genders.map((gen) => (
                            data[can] && data[can].general && data[can].general.status !== "error" ? <div key={i + gen}
                                                                                                          className={"skew-rect medium skew" +
                                                                                                          getAgeSkewLevel(can, gen, age, data)}
                                                                                                          data-tip={utils.candidateName(can) + "<br/>" + utils.capitalize(gen) + " " + utils.capitalize(ageDisplay(age)) + "<br/>" + utils.formatFracture(getAgeSkew(can, gen, age, data))}>
                            </div> : <div key = {i + gen}
                                          className={"skew-rect medium no-data"}
                                          data-tip = {utils.candidateName(can) + "<br/>" + "Audience is too narrow"}>
                            </div>

                        ))
                    }
                </div>

            ))
        }

    </div>;
}


const AudienceRace = ({candidates, data, race}) => {

    /*data for 1 race group

      Name1: {
          general: {
              data: {
                   race-skew: {
                      chinese: float
                      melay: float
                      ...
                  },
                  */

    const getRaceSkew = (can, race, data) => {

        let generalData = data[can].general.data;
        return utils.formatFracture(generalData['race-skew'][race]);
    }

    const getRaceSkewLevel = (can, race, data) => {

        let generalData = data[can].general.data;
        return generalData['race-skew-level'][race];
    }


    return <div className="grid">

        <div className="info first-item race">
            {race}
        </div>

        {
            candidates.map((can, i) => (

                <div key={i}>
                    {
                        data[can] && data[can].general && data[can].general.status !== "error" ?
                            <div
                                className={"skew-rect medium double skew" + getRaceSkewLevel(can, race, data)}
                                data-tip={utils.candidateName(can) + "<br/>" + utils.capitalize(race) + "<br/>" + utils.formatFracture(getRaceSkew(can, race, data))}>
                            </div> : <div
                        className={"skew-rect medium double no-data"}
                        data-tip = {utils.candidateName(can) + "<br/>" + "Audience is too narrow"}>
                        </div>
                    }
                </div>

            ))
        }

    </div>;
}

const ageDisplay = (age) => {
    if (age === '12-17') {
        age = '13-17';
    }
    return age;
}

const Audience = ({results, candidates, dataset}) => {

    let data = AudienceData.convert(results, candidates, dataset);

    if (!data) {
        return <WidgetError/>;
    }

    return (
        <div className="audience grid-item">
            <div className="title">Entity Audience Analysis</div>
            <div className="info">Indexation of the demographics consumption with each entity in the past month,<br/>compared
                with the general population
            </div>
            <AudienceCandidates candidates={candidates} data={data}/>

            <AudienceGender candidates={candidates} data={data}/>

            {
                data.ages.map((age, i) =>
                    <AudienceAge key={i}
                                 candidates={candidates}
                                 data={data}
                                 age={age}/>
                )
            }

            <div className="grid">
                <hr style={{'gridColumn': 'span 7'}}/>
            </div>

            {
                data.races.map((race, i) =>
                    <AudienceRace key={i}
                                  candidates={candidates}
                                  data={data}
                                  race={race}/>
                )
            }

        </div>
    );
};


export default Audience;
