import React from 'react';
import classNames from 'classnames';
import Calendar from 'react-calendar';
import moment from 'moment';
import utils from './../../common/utils';

import Icon from './../icon/icon';
import Checkbox from './../checkbox/checkbox';
import constants  from './../../common/constants';

import './selection.scss';
import './../dropdown/dropdown.scss';

class Selection extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      entitiesOpen: false,
      datasetsOpen: false,
      dataset: constants.getDefaultDataset()
    }
  }

  toggleEntitiesOpen(){
    let entitiesOpen = !this.state.entitiesOpen;
    let datasetsOpen = this.state.datasetsOpen;
    if(entitiesOpen){ //dont open both at once
      datasetsOpen = false;
    }

    this.setState({
      entitiesOpen: entitiesOpen,
      datasetsOpen: datasetsOpen
    }); 
  }

  toggleDatasetsOpen(){
    let datasetsOpen = !this.state.datasetsOpen;
    let entitiesOpen = this.state.entitiesOpen;
    if(datasetsOpen){ //dont open both at once
      entitiesOpen = false;
    }

    this.setState({
      entitiesOpen: entitiesOpen,
      datasetsOpen: datasetsOpen
    });
  }

  toggleDataset(date){

    let dataset = {
      start: moment(date).subtract(constants.DAYS_SPAN, "days").format(constants.DATE_FORMAT),
      end:   moment(date).format(constants.DATE_FORMAT)
    }
    
    this.setState({dataset, datasetsOpen: false}, ()=>{
      this.props.toggleDataset(dataset);
    });

  }

  datasetToCalendar(dataset){
    return moment(dataset.end, constants.DATE_FORMAT).toDate();
  }

  render(){

    let candidates = this.props.candidates || [];
    let allCandidates = this.props.allCandidates || [];

    return <div className="selection">
      
      <span className="pointer" onClick = {()=>this.toggleEntitiesOpen()}>
        <span className="title">Select Entities For Analysis</span>

        <Icon className="chevron" 
          iconId={this.state.entitiesOpen ? "chevron-up" : "chevron-down"} 
          height="11px" width="11px"></Icon>
      </span>

      <span className="pointer right" onClick = {()=>this.toggleDatasetsOpen()}>
        <span className="title">Select Timeframe For Analysis</span>
        
        &nbsp; &nbsp; &nbsp;
        
        {
          moment(this.state.dataset.start).format(constants.DATE_FORMAT_DISPLAY)+ 
          " - "+
         moment(this.state.dataset.end).format(constants.DATE_FORMAT_DISPLAY)
        }

        <Icon className="chevron" 
          iconId={this.state.datasetsOpen ? "chevron-up" : "chevron-down"} 
          height="11px" width="11px"></Icon>
      </span>
        
    
      <CandidatesSelection isOpen = {this.state.entitiesOpen} 
        candidates = {candidates}
        allCandidates = {allCandidates}
        toggleCandidate = {this.props.toggleCandidate}
        ></CandidatesSelection>

      <DatasetsSelection isOpen = {this.state.datasetsOpen} 
        dataset = {this.datasetToCalendar(this.state.dataset)}
        toggleDataset = {(dataset)=>this.toggleDataset(dataset)}
        ></DatasetsSelection>
    </div>
  }
};

const CandidatesSelection = ({isOpen, candidates, allCandidates, toggleCandidate}) => (

  <div className={ classNames('candidates-selection dropdown', { open: isOpen }) }>

    {
      allCandidates.sort().map((can, i)=>(
        <span className="checkbox-wrap" key = {i}>
          <Checkbox
            id = {can}
            label = {utils.candidateName(can)}
            isSelected = { candidates.includes(can) }
            onToggle = {toggleCandidate}
            ></Checkbox>
        </span>
      ))
    }
  </div>

)

const DatasetsSelection = ({isOpen, dataset, toggleDataset}) => {
  var date = new Date();
  var timezone = date.getTimezoneOffset(); //in minutes 
  let time = date.getTime() + timezone * 60 * 1000;

  let maxDate = new Date(time);
  let minDate = new Date(time);

  minDate.setDate(minDate.getDate() - constants.MAX_DAYS + 1 );

  //use only date from this date +
  let minimalForcedDate = new Date(constants.MINIMUM_FORCED_DATE); 
  if(minimalForcedDate.getTime() > minDate.getTime()){
    minDate = minimalForcedDate;
  }

  return (
    <div className={ classNames('datasets-selection', { open: isOpen }) }>
      <span className="calendar-title info">
        Choose the desired 7-day timeframe <br/>
        by selecting the end date
      </span>

      <Calendar className="dropdown"
        onChange={toggleDataset}
        value={dataset}
        maxDate = {maxDate}
        minDate = {minDate}
      />
    </div>
  )
}

export default Selection;
