//convert publishers by channel

import utils from './../../common/utils';

const getPublisherPlot = (results, candidate) => {
  let plot = results.data.plot[candidate];

  Object.keys(plot).forEach((pub) => {
    let isOther = true;
    Object.keys(publisherMap).forEach((label) => {
      if (publisherMap[label].includes(pub.toLowerCase())) {
        plot[pub].label = label;
        isOther = false;
      }
    });

    if (isOther) {
      plot[pub].label = ALTERNATIVE;
    }
  });
  return plot;
}

const getPlotMap = (plot) => {
  let plotMap = Object.keys(plot).filter((pub) => plot[pub].label);

  plotMap = plotMap.map((pub) => {
    return {
      publisher: pub,
      label: plot[pub].label,
      value: utils.round(plot[pub].normalized_value)
    };
  })

  plotMap = plotMap.sort((pub1, pub2) => {

    let comparison = 0;
    if (pub1.label > pub2.label) {
      comparison = 1;
    } else if (pub1.label < pub2.label) {
      comparison = -1;
    }
    return comparison;
  });

  return plotMap;
}

const getTotal = (plot) => {
  let total = 0;
  let publishers = Object.keys(plot);
  publishers.forEach((pub) => {
    total += plot[pub].normalized_value;
  });
  return total;
}

const getLabelPrecent = (label, plot, total) => {

  let sum = 0;
  Object.keys(plot).forEach((key) => {
    let pub = plot[key];
    if (pub.label === label) {
      sum += pub.normalized_value;
    }
  });

  let precent = Math.round(100 * sum / total);
  return precent;
}

const MAINSTREAM = "Mainstream",
  ALTERNATIVE = "Alternative / Forum",
  FOREIGN = "Foreign";

const publisherMap = {}

publisherMap[MAINSTREAM] = [
  'straitstimes.com',
  'channelnewsasia.com',
  'todayonline.com',
  'tnp.sg',
  'businesstimes.com.sg'
];


publisherMap[ALTERNATIVE] = [
  'statestimesreview.com',
  'singaporestatestimes.com',
  'criticalspectator.com',
  'allsingaporestuff.com',
  'theonlinecitizen.com',
  'theindependent.sg/tisg',
  'wakeupsg.com',
  'mustsharenews.com',
  'www.sgag.sg',
  'fabrications-about-the-pap.blogspot.com',
  'coconuts.co/singapore',
  'redwiretimes.com',
  'tremeritus.net',
  'newnaratif.com',
  'tnesingapore.wordpress.com',
  'unscrambled.sg',
  'fivestarsandamoon.com',
  'sureboh.sg',
  'theroyalsingapore.com',
  'ricemedia.co',
  'wonderwall.sg',
  'singaporeuncensored.com',
  'goodyfeed.com',
  'berthahenson.wordpress.com',
  'kirstenhan.com',
  'thehearttruths.com',
  'tankinlian.com',
  'sgalextan.com',
  'www.mravilaw.com',
  'www.sonnyliew.com',
  'lhuwenkai.com',
  'calvincheng.sg',
  'spinworldwide.org',
  'www.raviphilemon.net',
  'kenjeyaretnam.com',
  'jamus.name',
  'geraldgiam.sg',
  'yeejj.wordpress.com',
  'hardwarezone.com.sg',
  'forums.hardwarezone.com.sg/eat-drink-man-woman-16',
  'wordpress.com',
  'reddit.com',
  'reddit.com/r/singapore',
  'sgtalk.org',
  'mothership.sg',
  'msn.com/en-sg',
  'thinkchina.sg',
  'thekopi.co',
  'sghardtruth.com',
  'asiaone.com',
  'businessinsider.sg',
  'sgsme.sg',
  'razor.tv',
  'stomp.com.sg'
];


publisherMap[FOREIGN] = [
  'scmp.com',
  'bloomberg.com/asia',
  'reuters.com',
  'afp.com/en',
  'cnbc.com',
  'bbc.com',
  'nytimes.com',
  'independent.co.uk',
  'washingtonpost.com',
  'wsj.com',
  'theguardian.com',
  'telegraph.co.uk',
  'english.kyodonews.net',
  'asahi.com/ajw',
  'nhk.or.jp',
  'asia.nikkei.com',
  'sg.news.yahoo.com',
  'globaltimes.cn',
  'chinadaily.com.cn',
  'xinhuanet.com/english',
  'bernama.com/en',
  'nst.com.my',
  'thesundaily.my',
  'thestar.com.my',
  'freemalaysiatoday.com',
  'malaymail.com',
  'malaysiakini.com',
  'themalaysianinsight.com',
  'antaranews.com',
  'thejakartapost.com',
  'tempo.co',
  'jakartaglobe.id',
  'vulcanpost.com',
  'indiatimes.com',
  'edition.cnn.com',
  'bangkokpost.com',
  'japantimes.co.jp',
  'aljazeera.com',
  'manilatimes.net',
  'asiatimes.com',
  'indianexpress.com',
  'fox2now.com',
  'latimes.com',
  'news.com.au',
  'fox40.com',
  'ap.org',
  'cna.com.tw',
  'djnewswires.com',
  'www.epa.eu',
  'jijiweb.jiji.com/asia_info.html',
  'xinhua.org',
  'chinaview.cn',
  'www.welt.de',
  'paper.ce.cn',
  'economist.com',
  'ft.com/home/uk',
  'faz.net',
  'handelsblattglobal.com',
  'koreatimes.co.kr',
  'kwongwah.com.my',
  'nna.jp',
  'sankei.jp.msn.com',
  'thanhien.com.vn',
  'theedgemarkets.com/sg',
  'yahoo.com',
  'caixinglobal.com',
  'abs-cbn.com'
];

//to lower case
Object.keys(publisherMap).forEach((label) => {
  publisherMap[label].forEach((pub, i) => {
    publisherMap[label][i] = pub.toLowerCase();
  });
});

let channels_data = {

  convert: function (results, candidate, options, datasetOptions) {

    /*
    response - example for 2 names
    ========

    data: {

      plot: {
        Name1: {
          publisher1: {normalized_value: x, ...},
          publisher2: {normalized_value: x, ...},
          publisher3: {normalized_value: x, ...},
        },

        Name2: {...}
      },

      keys: [publisher1, publisher2, publisher3]
    },

    entities: [candidates names]


    output
    ======

    data: {

      labels: ['Mainstream', 'Alternative / Forum',  'Foreign'],

      datasets: [
        {
          data: [mainstream, alternative, foreign], 
          ...datasetOptions

     *** all sites not in any category goto 'alternative' 
        }
      ]
    },

    options: options, 


      //debug data
    candidate: candidate,

    total: total,

    plotMap: plot filtered by publishers 
          with a label in publishers map

*/

    let plot,
      total,
      plotMap,
      mainstream,
      alternative,
      foreign,
      output = null;

    try {

      plot = getPublisherPlot(results, candidate);
      total = getTotal(plot);

      plotMap = getPlotMap(plot);

      mainstream = getLabelPrecent(MAINSTREAM, plot, total);
      alternative = getLabelPrecent(ALTERNATIVE, plot, total);
      foreign = getLabelPrecent(FOREIGN, plot, total);

      output = {

        data: {
          labels: Object.keys(publisherMap),

          datasets: [{
            data: [mainstream, alternative, foreign],
            ...datasetOptions
          }]
        },

        options: options,

        //debug data
        candidate: candidate,

        total: utils.round(total),

        plotMap: plotMap
      }

      if (utils.isDebug()) {
        console.log("channels converted", output)
      }

    } catch (er) {
      console.error(er);
      return null;
    }

    return output;

  }
}

export default channels_data;
