import React from 'react';
import './footer.scss';
import amobeeLogo from '../../images/amobee-logo.png';

const Footer = (props) => (

  <div className="footer">

  <img className="amobee-logo" alt='Amobee' src={ amobeeLogo }/>
  <div className="info">
    Powered by Amobee brand intelligence technology. Amobee brand intelligence technology analyzes and correlates digital content consumption<br/>
    and consumption trends across web, video, mobile and social. 	
  </div>

  </div>
)

export default Footer;
