import React from 'react';
import Icon from './../../components/icon/icon';

import './header.scss';

const Header = ({isLandscape, setIsLandscape}) => (
	<div>
	  <div className="top-bar">
	      <div className="page-center top-bar-inner">
	        <span className="top-bar-title">Amobee Entity Dashboard</span>
	        <span className="powered-by">Powered by Amobee Brand Intelligence Technology</span>
	      </div>
	  </div>

	  <div className="page-center top-title">    
	      <div className="title">Which Entity Is Winning The Online Conversation?</div>

	      <div className="info">Customize the dashboard's visualization using the entity selection drop-down. Hover over the outside of each circle to see the percentage of mainstream, alternative/forum,<br/>
	      	and foreign publisher consumption for that entity. The entity generating the most digital content consumption in the last week is indexed at 100. 
	      </div>
	  </div>

	  <div className="settings">
	  	<span className="pointer" 
	  	      onClick={setIsLandscape}
	  		  title = "switch view: portrait / landscape">
	  		<Icon iconId="view" className={isLandscape ? 'landscape' : ''}/>
	  	</span>
	  </div>
  </div>
)

export default Header;